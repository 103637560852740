var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { attrs: { id: "faq-category-page" } },
    [
      _c("nav", { staticClass: "category-page-nav" }, [
        _c(
          "a",
          {
            staticClass: "category-link",
            attrs: { "aria-label": "Navigate to FAQ categories page" },
            on: { click: _vm.navigateToCategory },
          },
          [_vm._v("\n      Categories\n    ")]
        ),
        _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: "16",
              height: "16",
              viewBox: "0 0 16 16",
              fill: "none",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M6 4L10 8L6 12",
                stroke: "black",
                "stroke-width": "1.33333",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
              },
            }),
          ]
        ),
        _c("p", [_vm._v("\n      " + _vm._s(_vm.categoryName) + "\n    ")]),
      ]),
      _c("header", { staticClass: "faq-category-header" }, [
        _c("h1", [_vm._v("\n      " + _vm._s(_vm.categoryName) + "\n    ")]),
        _c("hr"),
      ]),
      _vm.isLoading
        ? _c("ct-centered-spinner")
        : _c("associated-pages", {
            staticClass: "mb-5",
            attrs: { "category-id": _vm.categoryId, "max-pages": 999 },
          }),
      _c("footer", { staticClass: "faq-category-footer" }, [
        _vm.pageRoute
          ? _c(
              "a",
              {
                staticClass: "category-page-link",
                attrs: { "aria-label": _vm.linkLabel },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.navigateToPage.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n      Explore the " + _vm._s(_vm.categoryName) + "\n      "
                ),
                _c("arrow-right", { staticClass: "arrow-icon" }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }